import { Storage } from 'aws-amplify'
import React, { useState } from 'react'

import { useAxios } from '../../utilities/Requests/useAxios'

export type MUMRecordObj = {
  id: number
  videoStorageKey: string
  recordDate: string
  reviewed: boolean
  reviewedBy: string
  reviewedAt: string
  videoURL: string
}

export interface MUMContextInterface {
  MUMRecords: MUMRecordObj[]
  getPatientMUMRecords: (patientId: number) => Promise<MUMRecordObj[]>
  reviewMumRecord: (mumRecordId: number) => Promise<any>
}

export const MUMContext = React.createContext<MUMContextInterface | null>(null)

export const MUMProvider = ({ children }: any) => {
  const { fetch } = useAxios()

  const [MUMRecords, setMUMRecords] = useState<MUMRecordObj[]>([])

  const getPatientMUMRecords = async (patientId: number) => {
    const { data, error } = await fetch({
      path: `Mum/GetPatientMumRecords?patientId=${patientId}`,
    })
    if (data) {
      const dataWithVideoPromises = data.map(async (r: MUMRecordObj) => {
        if (r.videoStorageKey) {
          r.videoURL = await Storage.get(r.videoStorageKey)
        }
        return r
      })
      const dataWithVideo = await Promise.all(dataWithVideoPromises)
      setMUMRecords(dataWithVideo)
      return data
    } else {
      console.warn(`getPatientMUMRecords-error: ${error}`)
    }
  }

  const reviewMumRecord = async (mumRecordId: number) => {
    const { error } = await fetch({
      path: `Mum/ReviewMumRecord?mumRecordId=${mumRecordId}`,
      methodType: 'PUT',
    })
    if (error) {
      console.warn(`reviewMumRecord-error: ${error}`)
    }
  }

  return (
    <MUMContext.Provider
      value={{
        MUMRecords,
        getPatientMUMRecords,
        reviewMumRecord,
      }}
    >
      {children}
    </MUMContext.Provider>
  )
}
