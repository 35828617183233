import dayjs from 'dayjs'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  DrinkCounterContext,
  DrinkCounterContextInterface,
  drinkCountRecordObj,
} from '../../providers/DrinkCounter/DrinkCounterProvider'
import {
  MUMContext,
  MUMContextInterface,
  MUMRecordObj,
} from '../../providers/MUM/MUMProvider'
import {
  BACContext,
  BACContextInterface,
  BACObj,
} from '../../providers/PatientChartPage/BACProvider'
import {
  PatientChartPageContext,
  PatientChartPageContextInterface,
} from '../../providers/PatientChartPage/PatientChartPage.provider'
import { MyJourneyChart } from './MyJourneyChart'
import { MyJourneyReviewTable } from './MyJourneyReviewTable'
import { useStyles } from './PatientChartPage.styles'

export const PatientChartPage = () => {
  const { classes } = useStyles()

  const { routedPatientId } = useParams()

  const { activeChartPatient, getPatientDetails } = useContext(
    PatientChartPageContext
  ) as PatientChartPageContextInterface

  const { getBacRecords, getPatientBacDailyAverages, bacRecordsWithVideo } =
    useContext(BACContext) as BACContextInterface

  const { getPatientDrinkCountRecords } = useContext(
    DrinkCounterContext
  ) as DrinkCounterContextInterface

  const { MUMRecords, getPatientMUMRecords } = useContext(
    MUMContext
  ) as MUMContextInterface

  // const mockBacRecords = [
  //   { recordDate: '2023-11-15T06:41:13.133Z', id: 1, value: 0.08 },
  //   { recordDate: '2023-11-17T06:41:13.133Z', id: 1, value: 0.06 },
  //   { recordDate: '2023-11-20T06:41:13.133Z', id: 1, value: 0.06 },
  //   { recordDate: '2023-12-05T06:41:13.133Z', id: 1, value: 0.03 },
  //   { recordDate: '2023-12-12T06:41:13.133Z', id: 1, value: 0.04 },
  //   { recordDate: '2023-12-15T06:41:13.133Z', id: 1, value: 0.07 },
  //   { recordDate: '2024-01-01T06:41:13.133Z', id: 1, value: 0.07 },
  //   { recordDate: '2024-01-15T06:41:13.133Z', id: 31, value: 0.05 },
  //   { recordDate: '2024-01-30T06:41:13.133Z', id: 32, value: 0.06 },
  //   { recordDate: '2024-01-31T06:41:13.133Z', id: 33, value: 0.04 },
  //   { recordDate: '2024-02-03T06:41:13.133Z', id: 34, value: 0.08 },
  //   { recordDate: '2024-02-04T06:41:13.133Z', id: 35, value: 0.06 },
  //   { recordDate: '2024-02-06T06:41:13.133Z', id: 36, value: 0.03 },
  //   { recordDate: '2024-02-07T06:41:13.133Z', id: 37, value: 0.04 },
  //   { recordDate: '2024-02-10T06:41:13.133Z', id: 38, value: 0.05 },
  //   { recordDate: '2024-02-12T06:41:13.133Z', id: 39, value: 0.09 },
  //   { recordDate: '2024-02-17T06:42:27.426Z', id: 40, value: 0.02 },
  //   { recordDate: '2024-03-12T06:42:06.945Z', id: 41, value: 0.12 },
  //   { recordDate: '2024-03-14T06:42:06.945Z', id: 42, value: 0.15 },
  //   { recordDate: '2024-03-16T06:42:23.2Z', id: 14, value: 0 },
  //   { recordDate: '2024-03-17T06:42:23.2Z', id: 14, value: 0.06 },
  //   { recordDate: '2024-03-19T06:42:23.2Z', id: 14, value: 0.03 },
  //   { recordDate: '2024-03-21T06:42:23.2Z', id: 14, value: 0.07 },
  //   { recordDate: '2024-03-22T06:42:23.2Z', id: 14, value: 0.05 },
  //   { recordDate: '2024-04-01T07:27:38.327Z', id: 7, value: 0.02 },
  //   { recordDate: '2024-04-04T07:27:38.327Z', id: 7, value: 0.07 },
  //   { recordDate: '2024-04-10T07:27:38.327Z', id: 7, value: 0.09 },
  //   { recordDate: '2024-04-13T07:27:38.327Z', id: 7, value: 0.02 },
  //   { recordDate: '2024-04-20T07:27:38.327Z', id: 7, value: 0.06 },
  //   { recordDate: '2024-04-26T07:27:38.327Z', id: 7, value: 0.05 },
  //   { recordDate: '2024-05-01T06:03:38.03Z', id: 2, value: 0.04 },
  //   { recordDate: '2024-05-10T06:03:38.03Z', id: 2, value: 0.06 },
  //   { recordDate: '2024-05-13T06:03:38.03Z', id: 2, value: 0.13 },
  //   { recordDate: '2024-05-18T06:03:38.03Z', id: 2, value: 0.04 },
  //   { recordDate: '2024-05-21T06:03:38.03Z', id: 2, value: 0.04 },
  //   { recordDate: '2024-05-22T06:05:52.903Z', id: 3, value: 0.06 },
  //   { recordDate: '2024-05-23T06:11:33.58Z', id: 4, value: 0 },
  //   { recordDate: '2024-05-24T06:13:43.774Z', id: 5, value: 0.07 },
  //   { recordDate: '2024-05-25T06:25:44.245Z', id: 6, value: 0.04 },
  //   { recordDate: '2024-06-10T06:40:48.75Z', id: 8, value: 0.03 },
  //   { recordDate: '2024-06-11T06:41:01.778Z', id: 9, value: 0.02 },
  //   { recordDate: '2024-06-13T06:41:55.863Z', id: 11, value: 0.1 },
  //   { recordDate: '2024-06-15T06:42:15.583Z', id: 13, value: 0.12 },
  //   { recordDate: '2024-06-18T06:42:40.409Z', id: 16, value: 0.02 },
  //   { recordDate: '2024-06-19T06:42:43.84Z', id: 17, value: 0.03 },
  //   { recordDate: '2024-06-20T06:42:48.746Z', id: 18, value: 0.04 },
  //   { recordDate: '2024-06-21T06:43:19.248Z', id: 19, value: 0.02 },
  //   { recordDate: '2024-06-22T06:43:26.06Z', id: 20, value: 0.03 },
  //   { recordDate: '2024-06-23T06:43:29.945Z', id: 21, value: 0.02 },
  //   { recordDate: '2024-06-24T06:51:30.734Z', id: 22, value: 0 },
  //   { recordDate: '2024-06-25T06:58:49.243Z', id: 23, value: 0 },
  //   { recordDate: '2024-06-26T07:27:38.327472Z', id: 24, value: 0 },
  //   { recordDate: '2024-06-27T07:27:38.327472Z', id: 24, value: 0.03 },
  //   { recordDate: '2024-06-30T07:27:38.327472Z', id: 24, value: 0.1 },
  //   { recordDate: '2024-07-02T08:24:11.926224Z', id: 25, value: 0.08 },
  //   { recordDate: '2024-07-04T08:24:11.926224Z', id: 25, value: 0.12 },
  //   { recordDate: '2024-07-05T08:24:11.926224Z', id: 25, value: 0.07 },
  //   { recordDate: '2024-07-10T08:24:11.926224Z', id: 25, value: 0.03 },
  //   { recordDate: '2024-07-12T08:24:11.926224Z', id: 25, value: 0.03 },
  //   { recordDate: '2024-07-15T08:24:11.926224Z', id: 26, value: 0.04 },
  //   { recordDate: '2024-07-17T08:24:11.926224Z', id: 27, value: 0.06 },
  //   { recordDate: '2024-07-18T08:24:11.926224Z', id: 28, value: 0.02 },
  //   { recordDate: '2024-07-19T08:24:11.926224Z', id: 29, value: 0.0 },
  //   { recordDate: '2024-07-20T08:24:11.926224Z', id: 30, value: 0.03 },
  //   { recordDate: '2024-07-22T08:24:11.926224Z', id: 31, value: 0.09 },
  //   { recordDate: '2024-07-23T08:24:11.926224Z', id: 32, value: 0.1 },
  //   { recordDate: '2024-07-24T08:24:11.926224Z', id: 33, value: 0.03 },
  //   { recordDate: '2024-07-25T08:24:11.926224Z', id: 33, value: 0.06 },
  //   { recordDate: '2024-07-26T08:24:11.926224Z', id: 33, value: 0.09 },
  //   { recordDate: '2024-07-27T08:24:11.926224Z', id: 33, value: 0.04 },
  //   { recordDate: '2024-07-29T08:24:11.926224Z', id: 33, value: 0.02 },
  //   { recordDate: '2024-07-31T08:24:11.926224Z', id: 33, value: 0.05 },
  //   { recordDate: '2024-08-03T08:24:11.926224Z', id: 33, value: 0.06 },
  //   { recordDate: '2024-08-05T08:24:11.926224Z', id: 33, value: 0.01 },
  //   { recordDate: '2024-08-07T08:24:11.926224Z', id: 33, value: 0.07 },
  //   { recordDate: '2024-08-09T08:24:11.926224Z', id: 33, value: 0.05 },
  //   { recordDate: '2024-08-11T08:24:11.926224Z', id: 33, value: 0.02 },
  //   { recordDate: '2024-08-13T08:24:11.926224Z', id: 33, value: 0.06 },
  //   { recordDate: '2024-08-15T08:24:11.926224Z', id: 33, value: 0.09 },
  //   { recordDate: '2024-08-17T08:24:11.926224Z', id: 33, value: 0.04 },
  //   { recordDate: '2024-08-19T08:24:11.926224Z', id: 33, value: 0.08 },
  //   { recordDate: '2024-08-21T08:24:11.926224Z', id: 33, value: 0.01 },
  //   { recordDate: '2024-08-24T08:24:11.926224Z', id: 33, value: 0.03 },
  //   { recordDate: '2024-08-26T08:24:11.926224Z', id: 33, value: 0.06 },
  //   { recordDate: '2024-08-30T08:24:11.926224Z', id: 33, value: 0.05 },
  //   { recordDate: '2024-09-01T08:24:11.926224Z', id: 33, value: 0.02 },
  //   { recordDate: '2024-09-03T08:24:11.926224Z', id: 33, value: 0.04 },
  //   { recordDate: '2024-09-04T08:24:11.926224Z', id: 33, value: 0.03 },
  // ]

  // 1W: Average of 1 day (7)
  // 1m: Average of 1 Day (28-31)
  // 3m Average of 1 week (13)
  // 6m Average of 1 week (26)
  // 12m Average of 2 week (26)
  const tabs = ['BAC', 'DrinkCounter'] //'Sleep', 'Heart Rate', 'BP'
  const timeRangeOptions = ['1w', '1m', '3m', '6m', '12m']
  const reviewOptions = ['Review BAC', 'Review Drink Types', 'Review MUM']
  const ListTable = ['Date', 'Time', 'Value']
  const ReviewBACTable = ['Date', 'Time', 'Value', 'Reviewed', 'Reviewed By']
  const ReviewMUMTable = ['Date', 'Time', 'Reviewed', 'Reviewed By']

  const [selectedTab, setSelectedTab] = useState('BAC')
  const [selectedRecordsAll, setSelectedRecordsAll] = useState<any[]>([])
  const [selectedRecordsDailyAvg, setSelectedRecordsDailyAvg] = useState<any[]>(
    []
  )
  const [selectedTimeRange, setSelectedTimeRange] = useState('1w')
  const [selectedReviewOption, setSelectedReviewOption] = useState('Review BAC')

  const [videoReviewFilter, setVideoReviewFilter] = useState(false)
  const [filteredBacWithVideos, setFilteredBacWithVideos] = useState<BACObj[]>(
    []
  )
  const [filteredMUMVideos, setFilteredMUMVideos] = useState<MUMRecordObj[]>([])

  useEffect(() => {
    if (routedPatientId) {
      getPatientDetails(parseInt(routedPatientId))
    }
  }, [routedPatientId])

  const fetchBACData = async () => {
    if (selectedTab === 'BAC' && routedPatientId) {
      const data = await getBacRecords(Number(routedPatientId))
      setFilteredBacWithVideos(data)
      setSelectedRecordsAll(data)
      // setSelectedRecordsAll(mockBacRecords)
      setSelectedRecordsDailyAvg(data)
      const avgRecords = await getPatientBacDailyAverages(
        Number(routedPatientId)
      )
      setSelectedRecordsDailyAvg(avgRecords)
    }
  }

  const fetchDrinkCounterData = async () => {
    if (routedPatientId) {
      const drinkCountRecords = await getPatientDrinkCountRecords(
        Number(routedPatientId)
      )
      setSelectedRecordsAll(drinkCountRecords)
      setSelectedRecordsDailyAvg(drinkCountRecords)
    }
  }

  const fetchMUMData = async () => {
    if (routedPatientId) {
      const MUMRecordsData = await getPatientMUMRecords(Number(routedPatientId))
      setFilteredMUMVideos(MUMRecordsData)
    }
  }

  useEffect(() => {
    fetchBACData()
    fetchDrinkCounterData()
    fetchMUMData()
  }, [])

  useEffect(() => {
    if (videoReviewFilter) {
      if (selectedReviewOption === 'Review BAC') {
        const filtered = bacRecordsWithVideo.filter((r: BACObj) => !r.reviewed)
        setFilteredBacWithVideos(filtered)
      }
      if (selectedReviewOption === 'Review MUM') {
        const filtered = MUMRecords.filter((v: MUMRecordObj) => !v.reviewed)
        setFilteredMUMVideos(filtered)
      }
    } else {
      if (selectedReviewOption === 'Review BAC') {
        setFilteredBacWithVideos(bacRecordsWithVideo)
      }
      if (selectedReviewOption === 'Review MUM') {
        setFilteredMUMVideos(MUMRecords)
      }
    }
  }, [videoReviewFilter])
  useEffect(() => {
    fetchBACData()
  }, [])

  const getFilteredRecords = () => {
    const getDateWithMonth = (date: Date, months: number) => {
      date.setMonth(date.getMonth() + months)
      return date
    }
    const sortedRecord = selectedRecordsAll.sort(
      (br1, br2) =>
        new Date(br2.recordDate).getTime() - new Date(br1.recordDate).getTime()
    )
    const date1WeekAgo = dayjs().subtract(7, 'day')
    const last1WeekFilteredRecords = sortedRecord.filter(
      (br: BACObj | drinkCountRecordObj) =>
        new Date(br.recordDate) > date1WeekAgo.toDate()
    )
    switch (selectedTimeRange) {
      case '1w': {
        return last1WeekFilteredRecords
      }
      case '6m': {
        const date6MonthAgoDate = getDateWithMonth(new Date(), -6) // six months ago date
        const last6MonthFilteredRecords = sortedRecord.filter(
          (br: BACObj | drinkCountRecordObj) =>
            new Date(br.recordDate) > date6MonthAgoDate
        )
        return last6MonthFilteredRecords
      }
      case '1m': {
        const date1MonthAgoDate = getDateWithMonth(new Date(), -1)
        const last1MonthFilteredRecords = sortedRecord.filter(
          (br: BACObj | drinkCountRecordObj) =>
            new Date(br.recordDate) > date1MonthAgoDate
        )
        return last1MonthFilteredRecords
      }
      case '3m': {
        const date3MonthAgoDate = getDateWithMonth(new Date(), -3)
        const last3MonthFilteredRecords = sortedRecord.filter(
          (br: BACObj | drinkCountRecordObj) =>
            new Date(br.recordDate) > date3MonthAgoDate
        )
        return last3MonthFilteredRecords
      }
      case '12m': {
        const date1YearAgoDate = getDateWithMonth(new Date(), -12)
        const last1YearFilteredRecords = sortedRecord.filter(
          (br: BACObj | drinkCountRecordObj) =>
            new Date(br.recordDate) > date1YearAgoDate
        )
        return last1YearFilteredRecords
      }
      default: {
        return last1WeekFilteredRecords
      }
    }
  }

  const renderDataList = () => {
    if (getFilteredRecords().length) {
      return (
        <table className={classes.reviewTable}>
          <thead className={classes.tableThead}>
            <tr>
              {ListTable.map((l) => {
                return (
                  <th
                    className={classes.tableTh}
                    scope='col'
                    key={`ListTable-${l}`}
                  >
                    {l}
                  </th>
                )
              })}
              {selectedTab === 'DrinkCounter' ? (
                <th
                  className={classes.tableTh}
                  scope='col'
                  key={`ListTable-goal`}
                >
                  Goal
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {getFilteredRecords().map((fr: any, i: number) => (
              <tr key={`table-record-${i}`}>
                <th className={classes.tableTh} scope='col'>
                  {dayjs(fr.recordDate).format('MM/DD/YYYY')}
                </th>
                <th className={classes.tableTh} scope='col'>
                  {dayjs(fr.recordDate).format('h:mm A')}
                </th>
                <th className={classes.tableTh} scope='col'>
                  {fr.value.toFixed(2)}
                </th>
                {selectedTab === 'DrinkCounter' && (
                  <th className={classes.tableTh} scope='col'>
                    {fr.goal || 'N/A'}
                  </th>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )
    } else {
      return (
        <span className={classes.recordItemTime}>
          This patient has no record yet.
        </span>
      )
    }
  }

  return (
    <div className={classes.patientChartContainer}>
      <section className={classes.patientChartInfoContainer}>
        <p className={classes.patientChartInfoCol}>
          <strong>{activeChartPatient.patientName}</strong>
        </p>
        <div className={classes.patientChartInfoCol}>
          <p className={classes.patientChartInfoCol}>
            <strong>DOB: </strong>{' '}
            {dayjs(activeChartPatient.dateOfBirth).format('MM/DD/YYYY')}
          </p>
          <p className={classes.patientChartInfoCol}>
            <strong>MRN: </strong> {activeChartPatient.mrn}
          </p>
        </div>
        <div className={classes.patientChartInfoCol}>
          <p className={classes.patientChartInfoCol}>
            <strong>Program: </strong>{' '}
            {activeChartPatient?.accessRoles &&
              activeChartPatient.accessRoles[0]?.description}
          </p>
          <p className={classes.patientChartInfoCol}>
            <strong>Admission Date: </strong>{' '}
            {activeChartPatient.admissionDate || 'NaN'}
          </p>
          <p className={classes.patientChartInfoCol}>
            <strong>Deactivated Date: </strong>{' '}
            {activeChartPatient.dischargeDate || 'NaN'}
          </p>
        </div>
      </section>

      <section className={classes.patientChartDataContainer}>
        <div className={classes.patientChartDataLeft}>
          <section className={classes.patientGraphContainer}>
            <div className={classes.graphTabs}>
              {tabs.map((t, i) => (
                <button
                  className={`${classes.graphTabButton} ${
                    selectedTab === t ? classes.graphTabButtonSelected : ''
                  }`}
                  key={`patientChart-tab-${i}`}
                  type='button'
                  // selected={selectedTab === t}
                  onClick={() => setSelectedTab(t)}
                >
                  <span className={classes.graphTabButtonTxt}>{t}</span>
                </button>
              ))}
            </div>
            <div className={classes.chart}>
              <MyJourneyChart
                timeRangeOptions={timeRangeOptions}
                selectedRecordsDailyAvg={selectedRecordsDailyAvg}
                filteredRecords={getFilteredRecords()}
                selectedTimeRange={selectedTimeRange}
                setSelectedTimeRange={setSelectedTimeRange}
              />
            </div>
          </section>
          <section className={classes.recordListWrapper}>
            {renderDataList()}
          </section>
        </div>
        <section className={classes.patientChartDataRight}>
          <div className={classes.graphTabs}>
            {reviewOptions.map((t, i) => (
              <button
                className={`${classes.graphTabButton} ${
                  selectedReviewOption === t
                    ? classes.graphTabButtonSelected
                    : ''
                }`}
                key={`patientChart-tab-${i}`}
                type='button'
                // selected={selectedTab === t}
                onClick={() => setSelectedReviewOption(t)}
              >
                <span className={classes.graphTabButtonTxt}>{t}</span>
              </button>
            ))}
          </div>

          <MyJourneyReviewTable
            routedPatientId={routedPatientId}
            selectedReviewOption={selectedReviewOption}
            ReviewBACTableTabs={ReviewBACTable}
            ReviewMUMTableTabs={ReviewMUMTable}
            videoReviewFilter={videoReviewFilter}
            setVideoReviewFilter={setVideoReviewFilter}
            filteredBacWithVideos={filteredBacWithVideos}
            setFilteredBacWithVideos={setFilteredBacWithVideos}
            filteredMUMVideos={filteredMUMVideos}
            setFilteredMUMVideos={setFilteredMUMVideos}
          />
        </section>
      </section>
    </div>
  )
}
